import { QuestionWithAnswer } from 'components/collapsableQuestion/collapsableQuestion';

type UseCSBTransitionPageReturn = {
  questionsWithAnswers: QuestionWithAnswer[];
};

export function useCSBTransitionPage(): UseCSBTransitionPageReturn {
  return {
    questionsWithAnswers: [
      {
        question: 'Why is College Schedule Builder getting replaced by ScheduleLab?',
        answer:
          'College Schedule Builder was grown out of a college project between a couple of friends. Over the years, the number of students using the service grew substantially but the code behind it did not. In order to provide the best experience, we have rebranded and overhauled the app to best meet CSB’s original mission.',
      },
      {
        question: 'Is my college supported in ScheduleLab?',
        answer:
          'If your college was in CSB, you will find it in ScheduleLab as well. We made sure to support all existing colleges and will continue to expand the list.',
      },
      {
        question: 'Will my saved schedules transfer to the new app?',
        answer:
          'Unfortunately, they will not. There were too many significant changes between the apps to feasibly bring them over. We recommend using ScheduleLab for all future semesters and only using CSB for your current one if you have a schedule already saved.',
      },
      {
        question: 'Can I still do everything I could do in CSB?',
        answer: 'Of course! ScheduleLab offers all the features of CSB plus additional features/optimizations.',
      },
      {
        question: 'Why the new name?',
        answer:
          'While descriptive, we felt that College Schedule Builder was a mouthful. Rather than constantly referring to the product by its acronym, we decided to rebrand and have the business & product share a name. After much consideration, we landed on ScheduleLab - a name that captures the kinds of problems we solve. We hope it’s a bit easier to remember!',
      },
      {
        question: 'Why not rebrand the existing app?',
        answer:
          "In addition to the aforementioned technical concerns, app stores also make certain properties of an app unchangeable after it has been created. For example, an app's unique identifier in the store commonly contains both the company and product name - both of which we have changed. To ensure consistency for all future users, we decided a full rebrand would be the best approach.",
      },
    ],
  };
}
